@use '@assuranceiq/react-components/styles/colors';

@use 'styles/modules/breakpoints';
@use 'styles/modules/constants';
@use 'styles/modules/spacing';

@use 'sass:color';

.header {
  background-color: constants.$header-bg;
  border: none;

  :global {
    .MuiToolbar-root {
      padding: 0 spacing.units(1.5);
      justify-content: flex-end;
    }

    // Hide open/close toggle on larger displays—we provide our own “expand menu” button
    // for sm+ displays in ConsoleSidebar
    .RaAppBar-menuButton {
      @include breakpoints.sm {
        display: none;
      }
    }
  }
}

.stack {
  display: flex;
  align-items: center;
  flex: 1;
}

.logo {
  display: flex;
  align-items: center;

  a {
    display: contents;
    color: inherit;
  }

  img {
    height: 20px;
    width: 24px;
    margin-right: spacing.units(1.5);
  }
}
